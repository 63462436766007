// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-promotions-js": () => import("./../../../src/templates/Promotions.js" /* webpackChunkName: "component---src-templates-promotions-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/Services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-testimonials-js": () => import("./../../../src/templates/Testimonials.js" /* webpackChunkName: "component---src-templates-testimonials-js" */),
  "component---src-templates-trane-js": () => import("./../../../src/templates/Trane.js" /* webpackChunkName: "component---src-templates-trane-js" */),
  "component---src-templates-what-we-do-js": () => import("./../../../src/templates/WhatWeDo.js" /* webpackChunkName: "component---src-templates-what-we-do-js" */)
}

